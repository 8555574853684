import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
userrole:string
showSidebar: boolean = true;
    constructor(private authService: AuthService,
                private router: Router) {
    }

    ngOnInit(): void {
      let role= localStorage.getItem('role')
      this.userrole = role === 'super-admin' ? 'super-admin' : '';
      if(role==='landlord'){
        this.userrole=role ==='landlord'?'landlord':""
        this.showSidebar = false; // Hide sidebar for landlords
        this.router.navigate(['/landloard-dashboard']);
      }
    }

    onLogout(): void {
        this.authService.logout();
        this.router.navigate(['/login']);
    }
    toggleSidebar(): void {
        this.showSidebar = !this.showSidebar;
    }

}
